<template>
  <div class="container-fluid">

    <div class="row pt-2">
      <div class="col">
        <h3>
          Upload new artwork
        </h3>
        <div>
          Publish new artwork on NotReal
        </div>
      </div>
    </div>

    <div v-if="canCreateAnotherEdition">
      <div class="row">
        <div class="col mt-5">

          <fieldset :disabled="isMetadataSuccess || somethingInFlight">

            <h4>Artwork details</h4>

            <form novalidate>

              <div class="form-group mt-4">
                <label for="artworkName">Artwork title <span class="text-danger">*</span></label>
                <input type="text"
                       class="form-control"
                       :class="{'is-valid':isNameValid}"
                       id="artworkName"
                       v-model="edition.name"
                       :maxlength="maxNameLength"
                       required/>
                <small class="form-text text-muted">
                  {{(edition.name || '').length}}/{{maxNameLength}}
                </small>
              </div>

              <div class="form-group mt-4">
                <label for="editionDescription">Artwork description <span class="text-danger">*</span></label>
                <textarea class="form-control"
                          :class="{'is-valid':isDescriptionValid}"
                          id="editionDescription"
                          rows="5"
                          v-model="edition.description"
                          :maxlength="maxDescriptionLength"
                          required>
              </textarea>
                <small class="form-text text-muted">
                  {{(edition.description || '').length}}/{{maxDescriptionLength}}
                </small>
              </div>

              <h4 class="mt-5">Editions and price</h4>

              <div class="form-group mt-4">
                <label for="editionSize">
                  Edition size <span class="text-danger">*</span>
                </label>

                <b-form-group>
                  <b-form-radio v-model="edition.totalAvailable" name="editionSize" value="1" class="pb-2"
                                @change="edition.showOther = false">
                    1
                  </b-form-radio>
                  <b-form-radio v-model="edition.totalAvailable" name="editionSize" value="3" class="pb-2"
                                @change="edition.showOther = false">
                    3
                  </b-form-radio>
                  <b-form-radio v-model="edition.totalAvailable" name="editionSize" value="5" class="pb-2"
                                @change="edition.showOther = false">
                    5
                  </b-form-radio>
                  <b-form-radio v-model="edition.showOther" name="editionSize" :value="true" class="pb-2"
                                @change="edition.showOther = true">
                    Other
                  </b-form-radio>
                </b-form-group>

                <input type="number" class="form-control" id="editionSize"
                       :max="maxEditionSize" min="1" step="1"
                       v-if="edition.showOther"
                       onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                       v-model="edition.totalAvailable"
                       :class="{'is-valid':isTotalAvailableValid}"
                       required/>

                <small class="form-text text-muted">
                  Max edition size {{maxEditionSize}} - historic sales indicate increasing scarcity typically demands
                  higher valuation
                </small>
              </div>

              <div class="form-group mt-5">
                <label for="priceInWei">Price of artwork <span class="text-danger">*</span></label>
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <span class="input-group-text">ETH</span>
                  </div>
                  <input type="number"
                         :class="{'is-valid':isPriceValid}"
                         class="form-control"
                         id="priceInWei"
                         v-model="edition.priceInEther"
                         placeholder="0.5..."
                         :min="minPricePerEditionInEther"
                         required/>
                  <div class="input-group-append">
                    <span class="input-group-text">$</span>
                    <span class="input-group-text">{{usdPrice()}}</span>
                  </div>
                </div>
                <div>
                  <small class="form-text text-muted">
                    Minimum price {{minPricePerEditionInEther}} ETH
                    <usd-price-per-ether class="float-right"></usd-price-per-ether>
                  </small>
                </div>
              </div>

              <h3 class="mt-5">Image/artwork usage</h3>

              <b-form-checkbox
                id="confirm-artwork-original"
                v-model="edition.confirmArtworkOriginal"
                name="confirm-artwork-original"
                class="mb-3 mt-3"
                :value="true"
                :unchecked-value="false"
              >
                I confirm the artwork is original <span class="text-danger">*</span>
              </b-form-checkbox>

              <b-form-checkbox
                id="confirm-only-on-ko"
                v-model="edition.confirmOnlyOnKo"
                name="confirm-only-on-ko"
                class="mb-3"
                :value="true"
                :unchecked-value="false"
              >
                I confirm the artwork does not appear on other blockchain platforms <span class="text-danger">*</span>
              </b-form-checkbox>

              <b-form-checkbox
                id="confirm-infringements"
                v-model="edition.confirmNoInfringements"
                name="confirm-infringements"
                class="mb-3"
                :value="true"
                :unchecked-value="false"
              >
                I confirm no copyright infringements have been breached <span class="text-danger">*</span>
              </b-form-checkbox>

              <div class="form-group mt-5">
                <h4>Are you happy to accept bids?</h4>
                <div class="pt-2 pb-2">
                  <toggle-button
                    class="hand-pointer"
                    :value="true"
                    color="#5165FF"
                    @change="edition.enableAuctions = $event.value"
                    :height="25"
                    :font-size="12">
                  </toggle-button>
                  <span>
                    Yes, I'm happy to accept bids
                  </span>
                  <small class="form-text text-muted">
                    Add your email address to your profile and we will email you when new bids are received
                  </small>
                </div>
              </div>

              <div class="form-group mt-5">
                <h4>Is this artwork a collaboration?</h4>
                <div class="pt-2 pb-2">
                  <toggle-button
                    class="hand-pointer"
                    color="#5165FF"
                    @change="edition.isCollaboration = $event.value"
                    :height="25"
                    :font-size="12">
                  </toggle-button>
                  <span>Yes, this is a collaboration artwork?</span>
                </div>
              </div>

              <div class="form-group" v-if="edition.isCollaboration">
                <label for="collaboratorsAddress">Enter collaborators Ethereum address</label>
                <input type="text"
                       class="form-control"
                       :class="{'is-valid':isValidCollaborationAddress}"
                       id="collaboratorsAddress"
                       v-model="edition.collaboratorsAddress"/>
              </div>

              <h3 class="mt-5">Update artwork</h3>

              <div class="form-group mt-4">
                <label for="metadataTags">Add tags <span class="text-danger">*</span></label>
                <multiselect
                  id="metadataTags"
                  :multiple="true"
                  v-model="edition.tags"
                  :close-on-select="false"
                  :hide-selected="true"
                  :limit="100"
                  :taggable="true"
                  @tag="addTag"
                  placeholder="Add the tags which represent your artwork"
                  :options="tags">
                </multiselect>
                <small class="form-text text-muted">
                  Add at least 1 tag
                </small>
              </div>

              <div class="form-group mt-5">
                <label for="nftImageInput">Upload file <span class="text-danger">*</span></label>
                <div class="custom-file">
                  <input id="nftImageInput"
                         type="file"
                         class="custom-file-input"
                         :class="{'is-valid':isImageValid}"
                         :disabled="isImgSaving"
                         @change="captureFile"
                         :accept="acceptedMimeTypes.join(',')"
                         required>
                  <label class="custom-file-label" for="nftImageInput">
                    Select NFT asset...
                  </label>
                </div>
                <div id="nftImageInputHint" class="form-text text-muted float-right small">
                  {{maxFileSizeMb}}mb max file size. Accepted file types png, apng, jpg & gif, svg and webp<!-- & webm-->
                </div>
              </div>

              <!-- Image upload errors and output -->
              <div class="form-group">
                <div v-if="imageUpload.fileFormatError" class="form-text text-danger small">
                  Invalid file format, see supported options above
                </div>

                <div v-if="imageUpload.fileSizeError" class="form-text text-danger small">
                  Max current file size supported it <strong>{{maxFileSizeMb}}mb</strong>
                </div>

                <div
                  v-if="(isImgInitial || isImgSaving) && !(imageUpload.fileFormatError || imageUpload.fileSizeError)"
                  class="form-text text-info small pb-3">
                  Uploading file to
                  <font-awesome-icon :icon="['fas', 'cube']"></font-awesome-icon>
                  IPFS
                  <font-awesome-icon :icon="['fas', 'spinner']" spin></font-awesome-icon>
                </div>

                <div v-if="isImgSuccess" class="form-text text-success small pb-3">
                  Successfully uploaded file to
                  <font-awesome-icon :icon="['fas', 'cube']"></font-awesome-icon>
                  IPFS
                </div>

                <div v-if="isImgSuccess">
                  <a target="_blank" :href="imageUpload.ipfsImage">
                    <edition-asset :edition="{lowResImg: imageUpload.ipfsImage}"
                                   :full="false">
                    </edition-asset>
                  </a>
                </div>

                <p v-if="imageUpload.uploadError" class="form-text text-warning small">
                  Unable to upload file, please try again<br/>
                  {{ imageUpload.uploadError }}
                </p>
              </div>

            </form>
          </fieldset>
        </div>
      </div>

      <div class="row pt-2">
        <div class="col">
          <div v-if="isMetadataInitial || isMetadataSaving" class="alert alert-secondary small" role="alert">
            Building edition ...
            <font-awesome-icon :icon="['fas', 'cube']" spin></font-awesome-icon>
          </div>

          <div v-if="isMetadataFailed" class="alert alert-warning small" role="alert">
            Unable to upload metadata, please try again<br/>
            {{ metadataIpfsUpload.uploadError }}
          </div>

          <div v-if="isSelfServiceTriggered(account)" class="alert alert-primary small" role="alert">
            Transaction initiated, please check your wallet
            <font-awesome-icon :icon="['fas', 'cog']" spin></font-awesome-icon>
          </div>

          <div v-if="isSelfServiceStarted(account)" class="alert alert-info small" role="alert">
            Your transaction is being confirmed...
            <font-awesome-icon :icon="['fas', 'cog']" spin></font-awesome-icon>
            <br/>
            <clickable-transaction :transaction="getSelfServiceTransactionForAccount(account)"></clickable-transaction>
          </div>

          <div v-if="isSelfServiceSuccessful(account)" class="alert alert-success small" role="alert">
            Artwork created please wait approximately 5 minutes before it appears on the site
            <br/>
            <clickable-transaction :transaction="getSelfServiceTransactionForAccount(account)"></clickable-transaction>
          </div>

          <div v-if="isSelfServiceFailed(account)" class="alert alert-warning small" role="alert">
            Transaction waiting to be confirmed, check your <a :href="etherscanAccountPage" target="_blank">account</a>
            <span v-if="getSelfServiceTransactionForAccount(account)">
              and view the transaction <clickable-transaction
              :transaction="getSelfServiceTransactionForAccount(account)"></clickable-transaction>
          </span>
            before trying again to prevent duplicate artwork listings.<br/>
            If you are stuck please reach out to the team on
            <a href="https://t.me/notreal_io" target="_blank">telegram</a> for assistance.
          </div>

        </div>
      </div>

      <div class="row pt-3">
        <div class="col">
          <button class="btn btn-block btn-success" disabled="disabled"
                  v-if="isSelfServiceSuccessful(account)">
            Artwork created
          </button>

          <button class="btn btn-block btn-primary"
                  v-if="!isSelfServiceSuccessful(account)"
                  :disabled="addArtworkButtonDisabled"
                  @click="createEdition">
            Add Artwork
          </button>
          <div class="pt-1 small text-muted">
            Your artwork should appear in approximately 5 minutes once the transaction is confirmed
          </div>
        </div>
      </div>

      <div class="row pt-3 pb-3">
        <div class="col small text-muted">
          <div class="pt-1">
            Token images are stored on a decentralised file store called
            <a href="https://ipfs.co" target="_blank">IPFS</a>
            <a class="hand-pointer" @click="expandIpfsData = !expandIpfsData">[Data]</a>
          </div>
        </div>
      </div>

      <div class="row pt-1" v-if="true">
        <div class="col small text-muted">
          <pre v-show="expandIpfsData">{{generateIPFSData()}}</pre>
        </div>
      </div>
    </div>

    <div v-else>

      <div class="row">
        <div class="col">
          <div class="alert alert-info mt-2">
            You have reached your daily upload limit. You can add more artwork to your collection in 24 hours.
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  console.log('self-service')

  import Web3 from 'web3';
  import {mapGetters, mapState} from 'vuex';
  import * as actions from '../../../store/actions';
  import * as mutations from '../../../store/mutation';
  import * as _ from 'lodash';
  import Multiselect from 'vue-multiselect';
  import tags from '../../../services/selfService/tags';
  import ipfsClient from '../../../services/ipfsClient';
  import UsdPricePerEther from '../../generic/USDPricePerEther';
  import {ToggleButton} from 'vue-js-toggle-button';
  import ClickableTransaction from '../../generic/ClickableTransaction';
  import EditionAsset from '../../generic/EditionAsset';
  import {ipfs32}   from '../../../utils';
  import {BFormGroup, BFormRadio, BFormCheckbox} from 'bootstrap-vue';

  const STATUS_INITIAL = 0;
  const STATUS_SAVING = 1;
  const STATUS_SUCCESS = 2;
  const STATUS_FAILED = 3;

  // TODO enable video support once ready
  const acceptedMimeTypesRequiringExtension = [/*'video/webm', */'image/svg+xml', 'image/webp'];
  const acceptedImageMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

  export default {
    name: 'selfService',
    components: {
      EditionAsset,
      ClickableTransaction,
      UsdPricePerEther,
      Multiselect,
      ToggleButton,
      ...{BFormGroup, BFormRadio, BFormCheckbox}
    },
    data() {
      return {
        tags: _.orderBy(_.map(tags, _.toLowerCase)),
        maxFileSizeMb: 35,
        maxEditionSize: 25,
        maxNameLength: 100,
        maxDescriptionLength: 1000,
        minPriceInEther: 0.04,
        acceptedMimeTypesRequiringExtension: acceptedMimeTypesRequiringExtension,
        acceptedImageMimeTypes: acceptedImageMimeTypes,
        acceptedMimeTypes: [...acceptedImageMimeTypes, ...acceptedMimeTypesRequiringExtension],
        // The form
        edition: {
          tags: [],
          external_uri: 'https://notreal.ai',
          enableAuctions: true,
          isCollaboration: false,
          collaboratorsAddress: '',
          priceInEther: null,
          showOther: false,
          confirmArtworkOriginal: false,
          confirmOnlyOnKo: false,
          confirmNoInfringements: false,
          fileMimeType: null,
          fileSizeInBytes: null
        },
        imageUpload: {
          ipfsImage: null,
          ipfsHash: null,
          uploadError: null,
          fileFormatError: false,
          isValidFileSize: false,
          currentStatus: null,
        },
        metadataIpfsUpload: {
          ipfsHash: null,
          uploadError: null,
          currentStatus: null,
        },
        expandIpfsData: false,
        editionBatch: []
      };
    },
    props: ['artist', 'canCreateAnotherEdition'],
    computed: {
      ...mapState([
        'account',
        'currentNetworkId',
        'etherscanBase',
        'currentUsdPrice',
        'notifier',
      ]),
      ...mapState('selfService', [
        'minPricePerEditionInEther',
      ]),
      ...mapGetters([
        'findArtistsForAddress',
      ]),
      ...mapGetters('kodaV2', [
        'editionsForArtist',
        'isStartDateInTheFuture'
      ]),
      ...mapGetters('selfService', [
        'isSelfServiceTriggered',
        'isSelfServiceStarted',
        'isSelfServiceSuccessful',
        'isSelfServiceFailed',
        'getSelfServiceTransactionForAccount',
      ]),
      isImgInitial() {
        return this.imageUpload.currentStatus === STATUS_INITIAL;
      },
      isImgSaving() {
        return this.imageUpload.currentStatus === STATUS_SAVING;
      },
      isImgSuccess() {
        return this.imageUpload.currentStatus === STATUS_SUCCESS;
      },
      isImgFailed() {
        return this.imageUpload.currentStatus === STATUS_FAILED;
      },
      isMetadataInitial() {
        return this.metadataIpfsUpload.currentStatus === STATUS_INITIAL;
      },
      isMetadataSaving() {
        return this.metadataIpfsUpload.currentStatus === STATUS_SAVING;
      },
      isMetadataSuccess() {
        return this.metadataIpfsUpload.currentStatus === STATUS_SUCCESS;
      },
      isMetadataFailed() {
        return this.metadataIpfsUpload.currentStatus === STATUS_FAILED;
      },
      somethingInFlight() {
        if (!this.account) {
          return true;
        }
        return this.isSelfServiceTriggered(this.account) || this.isSelfServiceStarted(this.account);
      },
      isNameValid() {
        if (_.size(_.trim(this.edition.name)) < 1 || _.size(_.trim(this.edition.name)) > this.maxNameLength) {
          console.log('Failed on [name] validation');
          return false;
        }
        return true;
      },
      isDescriptionValid() {
        if (_.size(_.trim(this.edition.description)) < 1 || _.size(_.trim(this.edition.description)) > this.maxDescriptionLength) {
          console.log('Failed on [description] validation');
          return false;
        }
        return true;
      },
      isTotalAvailableValid() {
        if (!this.edition.totalAvailable || this.edition.totalAvailable < 1 || (this.edition.totalAvailable > this.maxEditionSize)) {
          console.log('Failed on [totalAvailable] validation', this.edition.totalAvailable);
          return false;
        }
        console.log('Passed [totalAvailable] validation', this.edition.totalAvailable);
        return true;
      },
      isArtistValid() {
        if (!this.artist) {
          console.log('Failed on [artist] validation');
          return false;
        }
        return true;
      },
      isScarcityValid() {
        if (['ultrarare', 'rare', 'common'].indexOf(this.edition.scarcity) < 0) {
          console.log('Failed on [scarcity] validation');
          return false;
        }
        return true;
      },
      isPriceValid() {
        if (_.toString(this.edition.priceInEther) === '0') {
          console.log('Price in ETH set to zero - failing validation', this.edition.priceInEther);
          return false;
        }
        if (this.edition.priceInEther < this.minPriceInEther) {
          console.log('Failed on [priceInEther] failing - to low', this.edition.priceInEther, this.minPriceInEther);
          return false;
        }
        if (this.edition.priceInEther > 10000) {
          console.log('Failed on [priceInEther] validation - to high', this.edition.priceInEther);
          return false;
        }
        console.log('Passed [priceInEther] validation', this.edition.priceInEther);
        return true;
      },
      isImageValid() {
        if (_.size(this.imageUpload.ipfsHash) !== 46) {
          console.log('Failed on [ipfsHash] validation');
          return false;
        }
        return true;
      },
      isTagsValid() {
        if (this.edition.tags < 1 || this.edition.tags > 100) {
          console.log('Failed on [tags] validation');
          return false;
        }
        return true;
      },
      isValidCollaborationAddress() {
        if (this.edition.isCollaboration && !Web3.utils.isAddress(this.edition.collaboratorsAddress)) {
          console.log('Failed on [collaboration] validation');
          return false;
        }
        return true;
      },
      isValidConfirmationCheckboxes() {
        if (!this.edition.confirmArtworkOriginal || !this.edition.confirmOnlyOnKo || !this.edition.confirmNoInfringements) {
          console.log('Failed on [confirmation checkboxes] validation');
          return false;
        }
        return true;
      },
      addArtworkButtonDisabled() {
        if (!this.isEditionValid) {
          console.log(`Button disabled - edition data not valid`);
          return true;
        }
        if (this.isMetadataInitial || this.isMetadataSaving) {
          console.log(`Button disabled - metadata being saved`);
          return true;
        }
        if (this.somethingInFlight) {
          console.log(`Button disabled - transaction in flight`);
          return true;
        }
        console.log(`Button enabled - artwork ready to be tokenized`);
        return false;
      },
      isEditionValid() {
        if (!this.isNameValid) {
          return false;
        }
        if (!this.isDescriptionValid) {
          return false;
        }
        if (!this.isTotalAvailableValid) {
          return false;
        }
        if (!this.isPriceValid) {
          return false;
        }
        if (!this.isArtistValid) {
          return false;
        }
        if (!this.isScarcityValid) {
          return false;
        }
        if (!this.isImageValid) {
          return false;
        }
        if (!this.isTagsValid) {
          return false;
        }
        if (!this.isValidCollaborationAddress) {
          return false;
        }
        if (!this.isValidConfirmationCheckboxes) {
          return false;
        }
        return true;
      },
    },
    methods: {
      setScarcity() {
        this.$nextTick(() => {
          this.setEditionScarcity();
          this.$forceUpdate();
        });
      },
      setEditionScarcity() {
        const total = parseInt(this.edition.totalAvailable);
        if (total === 1) {
          this.edition.scarcity = 'ultrarare';
        } else if (total <= 10) {
          this.edition.scarcity = 'rare';
        } else {
          this.edition.scarcity = 'common';
        }
      },
      addTag(newTag) {
        this.edition.tags.push(_.trim(newTag));
      },
      usdPrice() {
        if (this.currentUsdPrice && this.edition.priceInEther) {
          const value = this.currentUsdPrice * this.edition.priceInEther;
          return value.toFixed(2);
        }
        return '0.00';
      },
      captureFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        this.uploadFile(file)
      },
      uploadFile(file) {
        return new Promise((resolve, reject) => {
          this.uploadFilePending(file, resolve, reject)
        })
      },
      uploadFilePending(file, resolve, reject) {
        this.imageUpload.fileFormatError = false;
        this.imageUpload.fileSizeError = false;
        this.imageUpload.currentStatus = STATUS_INITIAL;

        this.imageUpload.ipfsHash = null;
        this.imageUpload.ipfsImage = null;

        if (!file) {
          this.imageUpload.currentStatus = STATUS_FAILED;
          console.warn('No file found');
          reject('no file found')
          return;
        }

        //image/svg+xml - PASS
        //video/webm - PASS
        //image/webp - PASS
        //image/mp4 - PASS - Not supported by OpenSea
        //image/tiff - PASS (not widely used enough)

        const isValidFileType = _.indexOf(this.acceptedMimeTypes, file.type) > -1;
        const requiresFullFileExtension = _.indexOf(this.acceptedMimeTypesRequiringExtension, file.type) > -1;

        const fileSizeInMb = file.size / 1024 / 1024;
        const isValidFileSize = fileSizeInMb <= this.maxFileSizeMb;

        // If its a new video format, ensure it has a valid file extension
        const hasValidFileExtension = requiresFullFileExtension
          ? _.indexOf(['svg', 'webm', 'webp'], _.last(file.name.split('.')).toLowerCase()) > -1
          : true;

        console.log(`is valid size [${isValidFileSize}] size found [${fileSizeInMb}] - has valid extension [${hasValidFileExtension}]`);

        if (isValidFileType && isValidFileSize && hasValidFileExtension) {

          // Record the file type/size
          this.edition.fileMimeType = file.type;
          this.edition.fileSizeInBytes = file.size;

          const reader = new FileReader();

          // Onload hook - upload & pin to IPFS
          reader.onloadend = () => {

            const {update} = this.notifier.notification({
              eventCode: 'uploadEditionToIpfsStart',
              type: 'pending',
              message: 'Uploading to IPFS ☁️'
            });

            this.imageUpload.currentStatus = STATUS_SAVING;
            this.imageUpload.ipfsHash = null;
            this.imageUpload.ipfsImage = null;

            const buffer = Buffer.from(reader.result);

            const options = requiresFullFileExtension
              ? {path: `/images/${file.name}`, content: buffer, wrapWithDirectory: true}
              : buffer;

            ipfsClient.add(options, {pin: true})
              .then((response) => {
                console.log(`Saved to IPFS`, response);

                this.imageUpload.ipfsHash = requiresFullFileExtension
                  ? response[1].hash
                  : response[0].hash;

                this.imageUpload.ipfsImage = requiresFullFileExtension
                  ? `https://ipfs.infura.io/ipfs/${response[1].hash}/${file.name}`
                  : `https://ipfs.infura.io/ipfs/${response[0].hash}`;

                this.imageUpload.ipfsImage = ipfs32(this.imageUpload.ipfsImage)

                this.imageUpload.currentStatus = STATUS_SUCCESS;

                update({
                  eventCode: 'uploadEditionToIpfsSuccess',
                  type: 'success',
                  message: `Successfully uploaded to IPFS 🤟 <a href="${this.imageUpload.ipfsImage}" target="_blank">[link]</a>`
                });

                resolve(this.imageUpload.ipfsImage)
              })
              .catch((error) => {
                console.log('Failed to add asset', error);
                update({
                  eventCode: 'uploadEditionToIpfsFailed',
                  type: 'error',
                  message: 'Failed to upload file 😞'
                });

                this.imageUpload.currentStatus = STATUS_FAILED;
                this.imageUpload.uploadError = error;
                reject(error)
              });
          };

          reader.readAsArrayBuffer(file);

        } else {
          this.imageUpload.fileFormatError = !isValidFileType;
          this.imageUpload.fileSizeError = !isValidFileSize;
          reject('file error')
        }
      },
      uploadMetaData: function () {
        this.metadataIpfsUpload.currentStatus = STATUS_SAVING;
        this.metadataIpfsUpload.ipfsHash = null;

        const metadata = Buffer.from(JSON.stringify(this.generateIPFSData()));
        return ipfsClient.add(metadata, {pin: true})
          .then((response) => {
            console.log(response);
            this.metadataIpfsUpload.ipfsHash = response[0].hash;
            this.metadataIpfsUpload.currentStatus = STATUS_SUCCESS;
          })
          .catch((error) => {
            console.log('Failed to add metadata', error);
            this.metadataIpfsUpload.currentStatus = STATUS_FAILED;
            this.metadataIpfsUpload.uploadError = 'Failed to add metadata';
          });
      },
      generateIPFSData() {
        const tags = _.clone(this.edition.tags);
        let metadata = {
          'name': _.trim(this.edition.name),
          'description': _.trim(this.edition.description),
          'attributes': {
            'artist': this.artist.name,
            'scarcity': this.edition.scarcity,
            'tags': tags
          },
          'external_uri': 'https://notreal.ai',
          'image': this.imageUpload.ipfsImage ? this.imageUpload.ipfsImage : ''
        };
        if (this.imageUpload.ipfsImage) {
          const isWebM = this.edition.fileMimeType === 'video/webm';
          if (isWebM) {
            metadata['animation_url'] = this.imageUpload.ipfsImage;
          }
          // metadata['attributes']['file_size_bytes'] = this.edition.fileSizeInBytes;
          metadata['attributes']['asset_type'] = this.edition.fileMimeType;
        }
        return metadata;
      },
      artistAddress() {
        const artist = this.artist;
        if (!artist) {
          return {};
        }
        if (_.isArray(artist.ethAddress)) {
          return artist.ethAddress[0];
        }
        return artist.ethAddress;
      },
      createEdition() {
        if (this.isEditionValid) {
          this.$store.commit(`selfService/${mutations.SELF_SERVICE_RESET}`, {account: this.account});
          return this.uploadMetaData()
            .then(() => {
              const editionData = {
                artist: this.artistAddress(),
                totalAvailable: this.edition.totalAvailable,
                tokenUri: this.metadataIpfsUpload.ipfsHash,
                priceInWei: Web3.utils.toWei(this.edition.priceInEther, 'ether'),
                enableAuctions: this.edition.enableAuctions,
                optionalSplitAddress: this.edition.isCollaboration ? this.edition.collaboratorsAddress : null,
                startDate: this.edition.startDate,
                endDate: this.edition.endDate,
              };

              return this.$store.dispatch(`selfService/${actions.CREATE_SELF_SERVICE_EDITION}`, editionData)
            });
        }
      },
      etherscanAccountPage() {
        return `${this.etherscanBase}/address/${this.account}`;
      }
    },
    watch: {
      'edition.isCollaboration': function () {
        this.edition.collaboratorsAddress = '';
      },
      'edition.totalAvailable': function () {
        this.setScarcity();
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../../ko-colours';
  @import '../../../ko-card';

  .multiselect--active {
    z-index: 3;
  }
</style>
