<template>
  <div class="container-fluid">

    <div class="editions-wrap">
      <h3>Artist Controls
      </h3>
      <p>
        Control prices for your artworks and gift NFTs. <br/>
        <span class="text-muted small">
        Please check <a :href="etherscanBase" target="_blank">Etherscan</a> to confirm your transactions complete successfully.
      </span>
      </p>
    </div>

    <div class="pb-4">
      <table class="table table-sm">
        <tbody>
        <tr v-for="edition in editions" :key="edition.id" v-if="shouldShowControls(edition)">
          <td>
            <router-link :to="{ name: 'confirmPurchaseSimple', params: { editionNumber: edition.id }}">
              <edition-image class="img-thumbnail" style="max-width: 100px"
                             :edition="edition"
                             :id="edition.id"
                             :full="true"/>
            </router-link>
          </td>
          <td class="d-none d-md-table-cell pt-4">
            <div>
              {{ edition.name | truncate(18) }}
            </div>
            <div class="text-muted small">
              <remaining-count :totalAvailable="edition.totalAvailable" :totalSupply="edition.totalSupply">
              </remaining-count>
            </div>
          </td>
          <td class="pt-4">

            <form class="form-inline">
              <div class="form-group mx-sm-2">
                <input type="text" class="form-control form-control-sm"
                       :id="'address_' + edition.id" placeholder="0x123...."
                       v-model="form.gift[edition.edition]">
              </div>
              <button type="button" class="btn btn-outline-primary btn-sm"
                      :disabled="!isValidAddress(form.gift[edition.edition])"
                      @click="giftEdition(edition, form.gift[edition.edition])">Gift
              </button>
            </form>

            <view-transaction-details :transaction="form.giftTransactions[edition.edition]">
            </view-transaction-details>
          </td>
          <td class="pt-4">

            <form class="form-inline">
              <div class="form-group mx-sm-2">
                <div class="input-group input-group-sm">
                  <div class="input-group-prepend">
                    <div class="input-group-text">ETH</div>
                  </div>
                  <input type="number" class="form-control" style="max-width: 60px;"
                         :id="'price_' + edition.id"
                         :placeholder="form.minPricePerEditionInEther"
                         :min="form.minPricePerEditionInEther"
                         step="0.01"
                         v-model="form.price[edition.edition]">
                  <div class="input-group-append">
                    <div class="input-group-text">${{toUsdPrice(form.price[edition.edition])}}</div>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-outline-primary btn-sm"
                      :disabled="!form.price[edition.edition] || form.price[edition.edition] < form.minPricePerEditionInEther"
                      @click="updatePrice(edition, form.price[edition.edition])">Set Price
              </button>
            </form>

            <div class="small text-muted pl-3">
              Currently {{edition.priceInEther}} ETH = $ {{toUsdPrice(edition.priceInEther)}}
            </div>
            <div class="small text-muted pl-3">
              Min price <strong>{{form.minPricePerEditionInEther}} ETH</strong>
            </div>

            <view-transaction-details :transaction="form.priceTransactions[edition.edition]">
            </view-transaction-details>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import ViewTransactionDetails from '../../generic/ViewTransactionDetails';
  import * as actions from '../../../store/actions';
  import Web3 from 'web3';
  import Vue from 'vue';
  import RemainingCount from '../../v2/RemainingCount';
  import EditionImage from '../../generic/EditionImage';

  export default {
    name: 'artistEditionControls',
    components: {
      EditionImage,
      RemainingCount,
      ViewTransactionDetails,
    },
    props: {
      editions: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        form: {
          price: {},
          priceTransactions: {},
          gift: {},
          giftTransactions: {},
          minPricePerEditionInEther: 0.04
        }
      };
    },
    computed: {
      ...mapState([
        'account',
        'etherscanBase',
        'currentUsdPrice',
      ]),
      ...mapState('artistControls', [
        'owner',
        'paused',
      ])
    },
    methods: {
      shouldShowControls(edition) {
        return edition.totalAvailable - edition.totalSupply > 0;
      },
      async giftEdition(edition, addressOrEnsName) {
        if (edition && this.isValidAddress(addressOrEnsName)) {
          this.$store.dispatch(`artistControls/${actions.GIFT_EDITION}`, {edition, addressOrEnsName})
            .then((hash) => {
              Vue.set(this.form.giftTransactions, edition.edition, hash);
            });
        }
      },
      updatePrice(edition, priceInEth) {
        if (priceInEth && edition && parseFloat(priceInEth) >= this.form.minPricePerEditionInEther) {
          this.$store.dispatch(`artistControls/${actions.UPDATE_EDITION_PRICE}`, {
            edition,
            value: Web3.utils.toWei(priceInEth)
          })
            .then((hash) => {
              Vue.set(this.form.priceTransactions, edition.edition, hash);
            });
        }
      },
      isValidAddress(addressOrEnsName) {
        if (!addressOrEnsName || addressOrEnsName.length === 0) {
          return false;
        }
        const isAddress = Web3.utils.isAddress(addressOrEnsName);
        if (isAddress) {
          return isAddress;
        }
        return addressOrEnsName.length >= 3;
      },
      toUsdPrice(priceInEther) {
        if (!priceInEther) {
          return '0';
        }
        return (priceInEther * this.currentUsdPrice).toFixed(2);
      }
    },
    created() {
    }
  };
</script>

<style scoped lang="scss">

</style>
