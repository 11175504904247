<template>
  <span>USD @ {{usdPrice}} PER/ETH</span>
</template>

<script>
  import {mapState} from 'vuex';
  import * as _ from 'lodash';

  export default {
    name: 'usdPricePerEther',
    computed: {
      ...mapState([
        'currentUsdPrice',
      ]),
      usdPrice: function () {
        if (this.currentUsdPrice) {
          return _.toNumber(this.currentUsdPrice).toFixed(2);
        }
        return null;
      }
    }
  };
</script>

<style scoped>
  small {
    font-size: 0.7rem;
  }
</style>
