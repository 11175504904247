<template>
  <div class="bg-white p-4 mb-5" v-if="artist">
    <artist-short-bio :artist="artist"></artist-short-bio>

    <div v-if="artistStats" class="row text-center mt-4 bio artist-stats">
      <hr/>
      <div class="col px-0">
        <h6 class="text-muted">Sold</h6>
        <h6>{{ artistStats.salesCount }}️</h6>
      </div>
      <div class="col px-0">
        <h6 class="text-muted">Editions</h6>
        <h6>{{ artistStats.editionsCount }}</h6>
      </div>
      <div class="col px-0">
        <h6 class="text-muted">Total</h6>
        <h6>
          <price-in-eth :value="artistStats.totalValueInEth"></price-in-eth>
        </h6>
      </div>
      <div class="d-none d-sm-block d-md-block col px-0">
        <h6 class="text-muted">Highest</h6>
        <h6><price-in-eth :value="artistStats.highestSaleValueInEth"></price-in-eth></h6>️
      </div>

    </div>

    <div class="mt-2">
      <hr/>
      <h6>Artist bio</h6>
      <p class="bio">
        {{ artist.bio }}
      </p>
    </div>

    <div v-if="artist.website" class="mt-2">
      <hr/>
      <h6>Artist website</h6>
      <p class="website">
        <a :href="artist.website" target="_blank">{{artist.website}}</a>
      </p>
    </div>

    <div class="text-center mb-4">
      <a class="btn btn-sm btn-twitter" v-if="artist.twitter" :href="`https://twitter.com/${artist.twitter}`"
         target="_blank">Twitter</a>
      <a class="btn btn-sm btn-instagram" v-if="artist.instagram"
         :href="`https://www.instagram.com/${artist.instagram}`" target="_blank">Instagram</a>
    </div>

  </div>
</template>

<script>
  import ArtistShortBio from './ArtistShortBio';
  import {ARTIST_STATS} from '../../gql/queries';
  import PriceInEth from "../generic/PriceInEth";

  export default {
    name: 'artistPanel',
    components: {PriceInEth, ArtistShortBio},
    props: ['artist'],
    apollo: {
      artistStats: {
        query: ARTIST_STATS,
        variables() {
          if (this.artist) {
            return {
              address: this.artist.ethAddress[0].toLowerCase(),
            };
          }
          return {
            address: '0x0'
          };
        },
      }
    },
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';

  .bio {
    font-size: 0.75rem;
    color: $body-color;
  }

  .artist-stats {
    /*font-family: 'Roboto', sans-serif;*/
  }

  .website {
    font-size: 0.65rem;
  }

  h6 {
    color: $body-color;
  }

  .btn-twitter {
    background-color: #20a2eb;
    color: $body-bg;
  }

  .btn-telegram {
    background-color: #0088cc;
    color: $body-bg;
  }

  .btn-instagram {
    background-color: #c13584;
    color: $body-bg;
  }

  /* mobile only */
  @media screen and (max-width: 767px) {
    img {
      max-height: 75px;
    }
  }
</style>
