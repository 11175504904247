<template>
  <span class="text-mute" v-if="showZero || usdPrice">
    <span>${{ usdPrice }}</span>
  </span>
</template>

<script>
  /* global web3:true */

  import {mapState} from 'vuex';
  import {fromWei} from 'web3-utils';

  export default {
    name: 'usdPrice',
    props: ['priceInEther', 'priceInWei', 'showZero'],
    computed: {
      ...mapState([
        'currentUsdPrice',
      ]),
      etherPrice: function() {
        if (this.priceInWei) {
          const ethPrice = fromWei(this.priceInWei.toString(), 'ether');
          return ethPrice
        }

        return this.priceInEther
      },
      usdPrice: function () {
        if (this.currentUsdPrice && (this.showZero || this.etherPrice)) {
          const value = this.currentUsdPrice * this.etherPrice;
          let displayValue;
          if (value > 10000) {
            displayValue = Math
              .round(value)
              .toLocaleString('en-US', {
                style: 'currency', currency: 'USD'
              }).slice(1,-3);
              // Remove $ and decimals

          } else {
            displayValue = value.toFixed(2);
          }

          return displayValue;
        }
        return null;
      }
    }
  };
</script>

<style scoped>
  small {
    font-size: 0.75rem;
  }
</style>
