<template>
  <div class="container-fluid">

    <div class="row">
      <div class="col">

        History of sales and accepted bids<br/>
        <small class="text-muted" v-if="!showAll">Showing transactions where ETH was exchanged</small>
        <small class="text-muted" v-else>Showing all transaction</small>

        <div class="form-check float-right">
          <input class="form-check-input" type="checkbox" id="shouldOnlyMoneyCheck"
                 v-on:change="loadApiData" v-model="showAll" :value="true">
          <label class="form-check-label" for="shouldOnlyMoneyCheck">
            Show all
          </label>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col text-center">
        <loading-spinner v-if="isLoading"></loading-spinner>
      </div>
    </div>

    <lazy-component @show="visibilityChanged">

      <div v-if="events.length > 0" class="pb-4">

        <table class="table table-borderless">
          <tbody>
          <tr v-for="event in events" :key="event.id">
            <td class="align-middle" width="25%" v-if="event._args._editionNumber">
              <router-link
                :to="{ name: 'confirmPurchaseSimple', params: { editionNumber: event._args._editionNumber }}">
                <edition-image class="img-thumbnail"
                               :edition="getEdition(event._args._editionNumber)"
                               :full="true"/>
              </router-link>
            </td>
            <td class="align-middle">
              <!--{{ mapMobileEvent(event) }}-->
              <code class="">{{ mapEvent(event)}}</code>
              <div v-if="event._args._priceInWei && event._args._priceInWei !== '0'">
                <price-in-eth :value="event._args._priceInWei | toEth"></price-in-eth>
                <u-s-d-price-converter
                  :price-in-wei="event._args._priceInWei"
                  :usd-exchange-rate="event.exchangeRate.usd">
                </u-s-d-price-converter>
              </div>
              <div v-else-if="event._args._amount && event._args._amount !== '0'">
                <price-in-eth :value="event._args._amount | toEth"></price-in-eth>
                <u-s-d-price-converter
                  :price-in-wei="event._args._amount"
                  :usd-exchange-rate="event.exchangeRate.usd">
                </u-s-d-price-converter>
              </div>
              <div v-if="event._args._tokenId">
                <router-link :to="{ name: 'edition-token', params: { tokenId: event._args._tokenId.toString() }}"
                             class="badge badge-primary">
                  {{ event._args._tokenId.toString() }}
                </router-link>
              </div>
              <div v-if="event._args._buyer" class="pt-2">
                <span class="text-muted small">Owner: </span>
                <clickable-address :eth-address="event._args._buyer" class="small"></clickable-address>
              </div>
            </td>
            <td class="align-middle">
              <div class="small d-none d-sm-block">{{ event.blockTimestamp | moment('calendar')}}</div>
              <view-transaction-details :transaction="event.transactionHash" class="small"></view-transaction-details>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </lazy-component>

    <div class="text-muted small mb-5 mt-3 text-right">
      Updated every 5 minutes
      <br/>
      <small>USD prices powered by <a href="https://www.coingecko.com" target="_blank">coingecko</a></small>
    </div>

  </div>
</template>

<script>

  import {mapState} from 'vuex';
  import EditionImage from '../../generic/EditionImage';

  import {PAGES} from '../../../store/loadingPageState';
  import * as actions from '../../../store/actions';
  import * as _ from 'lodash';
  import {mapEvent, mapMobileEvent} from '../../../services/eventMapper';
  import PriceInEth from '../../generic/PriceInEth';
  import USDPriceConverter from '../../generic/USDPriceConverter';
  import ViewTransactionDetails from '../../generic/ViewTransactionDetails';
  import LoadingSpinner from '../../generic/LoadingSpinner';
  import ClickableAddress from '../../generic/ClickableAddress';

  export default {
    name: 'editionSalesEvents',
    components: {
      ClickableAddress,
      LoadingSpinner,
      ViewTransactionDetails,
      EditionImage,
      USDPriceConverter,
      PriceInEth
    },
    data() {
      return {
        PAGES,
        events: [],
        loaded: false,
        isLoading: false,
        showAll: false,
        resultsAvailable: 0
      };
    },
    props: ['artistAddress'],
    computed: {
      ...mapState([
        'eventService'
      ]),
    },
    methods: {
      mapMobileEvent,
      mapEvent,
      // We only trigger the loading when we become visible to save the extra load
      visibilityChanged() {
        if (!this.loaded) {
          this.loadApiData();
        }
      },
      loadApiData() {
        this.isLoading = true;
        this.loaded = true;
        this.events = [];

        this.$store.dispatch(`kodaV2/${actions.LOAD_EDITIONS_FOR_ARTIST}`, {
          artistAccount: this.artistAddress,
          availableForPurchaseOnly: this.availableForPurchaseOnly
        })
          .then((editions) => {
            this.editions = editions;

            return this.eventService.loadPurchaseEventsForEditions(_.map(this.editions, 'id'), this.showAll)
              .then((results) => {
                if (results.success) {
                  const {data, resultsAvailable} = results;
                  this.resultsAvailable = resultsAvailable;
                  _.forEach(data, (result) => {
                    this.events.push(result);
                  });
                }
              });
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      getEdition: function (edition) {
        return _.find(this.editions, {id: edition});
      },
    },
    watch: {
      artistAddress: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadApiData();
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../../../ko-colours';
  @import '../../../ko-card';

  .img-thumbnail {
    max-width: 200px;
  }
</style>
