<template>
  <div>

    <div class="mt-4">
      <div class="row editions-wrap">

        <div class="col-sm-6 col-lg-4 col-12">
          <artist-panel :artist="artist"></artist-panel>
        </div>

        <div class="col-sm-6 col-lg-8 col-12">

          <!-- ARTWORKS -->
          <b-tabs content-class="mt-2" justified>

            <b-tab title="Artworks" active>

              <div class="row">
                <div class="col text-left ml-2">
                  <h6 class="text-secondary">Filter by</h6>
                  <toggle-button
                    class="hand-pointer"
                    :value="true"
                    :disabled="isLoading(PAGES.ARTISTS)"
                    @change="availableForPurchaseOnly=!availableForPurchaseOnly"
                    :labels="{checked: 'On sale', unchecked: 'Full collection'}"
                    :width="availableForPurchaseOnly ? 80 : 115"
                    :height="22"
                    :font-size="12">
                  </toggle-button>
                </div>
              </div>

              <loading-section :page="PAGES.ARTISTS"></loading-section>

              <div class="row" v-if="!isLoading(PAGES.ARTISTS) && (!editions || editions.length === 0)">
                <div class="col text-center">
                  <p class="text-muted">
                    No artworks currently listed for sale
                  </p>
                </div>
              </div>

              <div class="card-deck">
                <div class="col-sm-6 mb-5"
                     v-for="edition in editions" :key="edition.edition"
                     v-if="edition.active">
                  <gallery-card :edition="edition" :edition-number="edition.edition"></gallery-card>
                </div>
              </div>


            </b-tab>

            <!-- OFFERS/BIDS -->
            <b-tab title="Offers">
              <accept-edition-bids :artist="artist"></accept-edition-bids>
            </b-tab>

            <!-- SALES -->
            <b-tab title="Sales">
              <edition-sales-events :artist-address="artistAddress"></edition-sales-events>
            </b-tab>

            <!-- ARTWORKS CONTROLS -->
            <b-tab title="Manage artwork" v-if="shouldShowArtistTabs" v-cloak>
              <artist-edition-controls :editions="editions"></artist-edition-controls>
            </b-tab>

            <!-- ARTISTS CONTROLS -->
            <b-tab title="Profile" v-if="shouldShowArtistTabs" v-cloak>
              <artist-profile-controls :artist="artist"></artist-profile-controls>
            </b-tab>

            <!-- SELF SERVICE -->
            <b-tab title="Upload" v-if="shouldShowSelfServiceTabs" v-cloak>
              <self-service :artist="artist"
                            :can-create-another-edition="canCreateAnotherEdition || (account === selfServiceOwner)">
              </self-service>
            </b-tab>

            <!--&lt;!&ndash; SELF SERVICE &ndash;&gt;-->
            <!--<b-tab title="Downloads" v-if="shouldShowSelfServiceTabs">-->
            <!--<add-high-res :artist="artist"></add-high-res>-->
            <!--</b-tab>-->

          </b-tabs>
        </div>

      </div>
    </div>


  </div>
</template>

<script>

  import {mapGetters, mapState} from 'vuex';
  import * as _ from 'lodash';
  import ArtistPanel from '../components/artist/ArtistPanel';
  import * as actions from '../store/actions';
  import {PAGES} from '../store/loadingPageState';
  import LoadingSection from '../components/generic/LoadingSection';
  import AcceptEditionBids from '../components/artist/tabs/AcceptEditionBids';
  import GalleryCard from '../components/cards/GalleryCard';
  import ArtistEditionControls from '../components/artist/tabs/ArtistEditionControls';
  import EditionSalesEvents from '../components/artist/tabs/EditionSalesEvents';
  import SelfService from '../components/artist/tabs/SelfService';
  import ArtistProfileControls from '../components/artist/tabs/ArtistProfileControls';
  import {ToggleButton} from 'vue-js-toggle-button';
  import {BTabs, BTab} from 'bootstrap-vue';

  export default {
    name: 'artistPage',
    metaInfo() {
      return {
        title: `${this.artist.name} - ${this.artist.shortDescription}`
      };
    },
    components: {
      ArtistProfileControls,
      SelfService,
      EditionSalesEvents,
      ArtistEditionControls,
      GalleryCard,
      AcceptEditionBids,
      LoadingSection,
      ArtistPanel,
      ToggleButton,
      ...{BTabs, BTab}
    },
    data() {
      return {
        PAGES: PAGES,
        editions: null,
        availableForPurchaseOnly: true,
        isEnabledForAccount: false,
        canCreateAnotherEdition: false
      };
    },
    computed: {
      ...mapState([
        'account',
        'editionLookupService',
      ]),
      ...mapGetters([
        'findArtistsForAddress',
      ]),
      ...mapGetters('kodaV2', [
        'editionsForArtist',
        'isStartDateInTheFuture'
      ]),
      ...mapState('artistControls', [
        'owner',
        'paused',
      ]),
      ...mapState('selfService', {
        'selfServiceOwner': 'owner',
        'selfServicePaused': 'paused',
      }),
      ...mapGetters('loading', [
        'isLoading'
      ]),
      artist() {
        return this.findArtistsForAddress(this.$route.params.artistAccount);
      },
      artistAddress() {
        const artist = this.artist;
        if (!artist) {
          return null;
        }
        if (_.isArray(artist.ethAddress)) {
          return artist.ethAddress[0];
        }
        return artist.ethAddress;
      },
      shouldShowArtistTabs() {
        if (this.paused) {
          console.log('Artist Tabs are paused');
          return false;
        }
        // If logged in account is the smart contract owner
        if ((this.account && this.owner) && (this.account === this.owner)) {
          console.log('Showing artists as owner is logged in');
          return true;
        }
        return this.isArtistViewingPage;
      },
      /*
      shouldShowSelfServiceTabs() {
        return true; // temp
      },
      */

      shouldShowSelfServiceTabs() {
        if (this.selfServicePaused) {
          console.log('Self Service Tab is paused');
          return false;
        }
        // If logged in account is the smart contract owner
        if ((this.account && this.selfServiceOwner) && (this.account === this.selfServiceOwner)) {
          console.log('Showing artists as owner is logged in');
          return true;
        }
        return this.isArtistViewingPage && this.isEnabledForAccount;
      },
      isArtistViewingPage() {
        return this.account && this.artistAddress && (this.artistAddress === this.account);
      }
    },
    methods: {
      loadApiData() {
        this.$store.dispatch(`loading/${actions.LOADING_STARTED}`, PAGES.ARTISTS);
        this.$store.dispatch(actions.LOAD_ARTISTS)
          .then(() => {
            return this.$store.dispatch(`kodaV2/${actions.LOAD_EDITIONS_FOR_ARTIST}`, {
              artistAccount: this.artistAddress,
              availableForPurchaseOnly: this.availableForPurchaseOnly
            })
              .then((editions) => {
                this.editions = editions;
              });
          })
          .finally(() => {
            this.$store.dispatch(`loading/${actions.LOADING_FINISHED}`, PAGES.ARTISTS);
          });
      }
    },
    watch: {
      availableForPurchaseOnly(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadApiData();
        }
      }
    },
    created() {
      this.$store.watch(
        () => this.editionLookupService.currentNetworkId,
        () => this.loadApiData()
      );

      if (this.editionLookupService.currentNetworkId) {
        this.loadApiData();
      }

      const checkSelfService = () => {
        this.$store.dispatch(`selfService/${actions.GET_SELF_SERVICE_ENABLED_FOR_ACCOUNT}`, {artistAccount: this.artistAddress})
          .then(({canCreateAnotherEdition, isEnabledForAccount}) => {
            console.log(`Account canCreateAnotherEdition [${canCreateAnotherEdition}] isEnabledForAccount [${isEnabledForAccount}]`);
            this.canCreateAnotherEdition = canCreateAnotherEdition;
            this.isEnabledForAccount = isEnabledForAccount;
          });
      };

      this.$store.watch(
        () => this.$store.state.SelfServiceEditionCuration,
        () => checkSelfService()
      );

      if (this.$store.state.SelfServiceEditionCuration) {
        checkSelfService();
      }

    },
    destroyed() {
    }
  };
</script>

<style scoped lang="scss">
  @import '../ko-colours.scss';
  @import '../ko-card.scss';

  [v-cloak] {
    display: none
  }
</style>
